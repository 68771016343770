
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4 3C3.20435 3 2.44129 3.31607 1.87868 3.87868C1.31607 4.44129 1 5.20435 1 6V6.98325C0.999831 6.99388 0.999831 7.0045 1 7.01512V18C1 19.6523 2.34772 21 4 21H13C13.5523 21 14 20.5523 14 20C14 19.4477 13.5523 19 13 19H4C3.45228 19 3 18.5477 3 18V8.8204L10.4337 13.5442L10.4391 13.5475C10.9069 13.8407 11.4479 13.9961 12 13.9961C12.5521 13.9961 13.0931 13.8407 13.5609 13.5476L21 8.8204V13C21 13.5523 21.4477 14 22 14C22.5523 14 23 13.5523 23 13V7.01495C23.0002 7.00445 23.0002 6.99393 23 6.98341V6C23 5.20435 22.6839 4.44129 22.1213 3.87868C21.5587 3.31607 20.7957 3 20 3H4ZM21 6.45076V6C21 5.73478 20.8946 5.48043 20.7071 5.29289C20.5196 5.10536 20.2652 5 20 5H4C3.73478 5 3.48043 5.10536 3.29289 5.29289C3.10536 5.48043 3 5.73478 3 6V6.45076L11.5032 11.8541C11.6522 11.9469 11.8244 11.9961 12 11.9961C12.1756 11.9961 12.3478 11.9469 12.4968 11.8541L12.4991 11.8527L21 6.45076ZM16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L19 17.5858L20.2929 16.2929C20.6834 15.9024 21.3166 15.9024 21.7071 16.2929C22.0976 16.6834 22.0976 17.3166 21.7071 17.7071L20.4142 19L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L19 20.4142L17.7071 21.7071C17.3166 22.0976 16.6834 22.0976 16.2929 21.7071C15.9024 21.3166 15.9024 20.6834 16.2929 20.2929L17.5858 19L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929Z" fill="black"/>
</svg>

  </template>

  <script>
  export default {
    name: 'NoResponse',
    inheritAttrs: true,
  }
  </script>
